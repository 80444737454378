var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.loadingDisciplineCategories)?_c('div',{staticClass:"hide-by-print"},[_c('b-row',{staticClass:"my-1"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"currentDisciplineCategory"}},[_vm._v(_vm._s(_vm.$tc("sportevent.scoringsheets"))+" - "+_vm._s(_vm.$tc("sporteventCalculation.discipline"))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('multiselect',{attrs:{"options":_vm.allDisciplineCategories,"custom-label":_vm.getDisciplineCategoryName,"placeholder":_vm.$t('labels.selectone'),"allowEmpty":false,"track-by":"id"},on:{"input":_vm.disciplineCategoriesChanges},model:{value:(_vm.currentDisciplineCategory),callback:function ($$v) {_vm.currentDisciplineCategory=$$v},expression:"currentDisciplineCategory"}})],1)],1)],1):_vm._e(),(!_vm.loading && _vm.currentDisciplineCategory.id)?_c('div',[(!_vm.isSwimDiscipline)?_c('div',_vm._l((_vm.startGroups),function(startGroup){return _c('table',{key:startGroup.id,staticClass:"table b-table table-striped print-page-break-after"},[(startGroup.starters.length > 0 && _vm.firstDiscipline)?_c('thead',[_c('tr',[_c('th',{staticClass:"textcenter",staticStyle:{"border":"none"},attrs:{"colspan":"100%"}},[_c('span',{staticClass:"bigger"},[_vm._v(" "+_vm._s(startGroup.StartGroupName)+" - "+_vm._s(_vm.currentDisciplineCategory.name)+" ")]),_c('br'),_vm._v(" Startblock: "+_vm._s(startGroup.startBlock.name)+" ")])]),_c('tr',[_c('th',{staticStyle:{"width":"0.2em"}}),_c('th',{staticClass:"textleft",staticStyle:{"width":"15em"}},[_vm._v(" "+_vm._s(_vm.$tc("sportevent.starter"))+" ")]),_c('th',{staticClass:"textcenter",staticStyle:{"width":"3em"}}),_c('th',[_c('b-row',{staticClass:"zero-spaceing"},[(
                    _vm.firstDiscipline !== undefined &&
                    _vm.firstDiscipline.hasPenaltyPoints
                  )?_c('b-col',{staticClass:"zero-spaceing",attrs:{"sm":"2"}},[_vm._v(" "+_vm._s(_vm.$t("sportevent.penaltyPoints"))+" ")]):_vm._e(),_c('b-col',{staticClass:"zero-spaceing"},[(
                      _vm.firstDiscipline !== undefined &&
                      _vm.firstDiscipline.hasBPoints
                    )?_c('span',[_vm._v(_vm._s(_vm.$t("sportevent.aValue")))]):_c('span',[_vm._v(_vm._s(_vm.$t("sportevent.performance")))])]),(
                    _vm.firstDiscipline !== undefined &&
                    _vm.firstDiscipline.hasBPoints
                  )?_c('b-col',{staticClass:"zero-spaceing",attrs:{"sm":"7"}},[_vm._v(_vm._s(_vm.$t("sportevent.bValue")))]):_vm._e()],1)],1)])]):_vm._e(),_c('tbody',_vm._l((startGroup.starters),function(starter){return _c('tr',{key:starter.id,attrs:{"set":(_vm.currentDiscipline = _vm.getCurrentDiscipline(
                starter.sporteventCompetition.id
              ))}},[_c('td',[_vm._v(_vm._s(starter.positionInStartgroup))]),_c('td',{staticClass:"startername textleft"},[_c('strong',[_vm._v(" "+_vm._s(starter.person.firstname)+" "+_vm._s(starter.person.lastname)+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(starter.startsForSociety.shortname)+" "),_c('br'),_vm._v(" "+_vm._s(starter.sporteventCompetition.competition.name)+" ")])]),_c('td',{staticClass:"textcenter disciplinetype"},[(
                  _vm.currentDiscipline != undefined &&
                  !_vm.currentDiscipline.hasBPoints
                )?_c('span',[_vm._v(_vm._s(_vm.getDisciplineType(_vm.currentDiscipline.name)))]):_vm._e()]),_c('td',{staticClass:"zero-spaceing"},[(
                  _vm.currentDiscipline != undefined &&
                  _vm.currentDiscipline.repetition !== undefined &&
                  _vm.currentDiscipline.hasBPoints
                )?_c('div',_vm._l((_vm.currentDiscipline.repetition),function(row){return _c('b-row',{key:row,staticClass:"zero-spaceing"},[_c('b-col',{staticClass:"zero-spaceing point-container",attrs:{"sm":"2"}},[_c('div',{class:`penaltyPoints${_vm.currentDiscipline.repetition}`})]),_c('b-col',{staticClass:"zero-spaceing point-container"},[_c('div',{class:`aValue${_vm.currentDiscipline.repetition}`})]),_c('b-col',{staticClass:"zero-spaceing point-container",attrs:{"sm":"7","set":(_vm.juryCount = _vm.getCurrentJuryCount(
                        startGroup.startBlock,
                        _vm.currentDiscipline.disciplineCategory
                      ))}},_vm._l((_vm.juryCount),function(juryCountindex){return _c('div',{key:juryCountindex,class:`bValue${_vm.currentDiscipline.repetition}`})}),0)],1)}),1):_c('div',[_c('b-row',{staticClass:"zero-spaceing"},[(
                      _vm.currentDiscipline != undefined &&
                      _vm.currentDiscipline.hasPenaltyPoints
                    )?_c('b-col',{staticClass:"zero-spaceing point-container",attrs:{"sm":"2"}},[_c('div',{staticClass:"penaltyPoints1"})]):_vm._e(),(
                      _vm.currentDiscipline != undefined &&
                      _vm.currentDiscipline.repetition !== undefined
                    )?_c('b-col',{staticClass:"zero-spaceing point-container"},_vm._l((_vm.currentDiscipline.repetition),function(row){return _c('div',{key:row,staticClass:"aValue1"})}),0):_vm._e()],1)],1)])])}),0)])}),0):_c('div',_vm._l((_vm.startGroups),function(startGroup){return _c('div',{key:startGroup.id,staticClass:"table b-table table-striped print-page-break-after"},_vm._l((startGroup.starters),function(starter){return _c('table',{key:starter.id,staticClass:"swimsheet",attrs:{"set":(_vm.currentDisciplines = _vm.getCurrentDisciplines(
              starter.sporteventCompetition.id
            ))}},[(_vm.currentDisciplines[0])?_c('tbody',[_vm._m(0,true),_c('tr',[_c('th',{attrs:{"colspan":"100%"}},[_vm._v(" "+_vm._s(startGroup.StartGroupName)+" "),(_vm.currentDisciplines.length === 1)?_c('span',[_vm._v(" - Bahn "+_vm._s(starter.positionInStartgroup)+" ")]):_vm._e()])]),_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[(_vm.currentDisciplines.length > 1)?_c('ul',[_c('span',{staticClass:"disciplinenote"},[_vm._v("Zutreffendes ankreuzen:")]),_vm._l((_vm.currentDisciplines),function(currentDiscipline){return _c('li',{key:currentDiscipline.id},[_vm._v(" ◻ "+_vm._s(currentDiscipline.name)+" ")])})],2):_c('span',[(_vm.currentDisciplines[0])?_c('span',[_vm._v(_vm._s(_vm.currentDisciplines[0].name))]):_vm._e()])])]),_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_vm._v(" "+_vm._s(starter.sporteventCompetition.competition.name)+" ")])]),_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_vm._v(" "+_vm._s(starter.person.firstname)+" "),_c('strong',[_vm._v(_vm._s(starter.person.lastname))])])]),_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_vm._v(" "+_vm._s(starter.startsForSociety.shortname)+" ")])]),_vm._m(1,true),_vm._m(2,true)]):_vm._e()])}),0)}),0)]):_vm._e(),(_vm.loading)?_c('vue-loading'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',{staticClass:"space",attrs:{"colspan":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"swimvalue"},[_c('td',[_vm._v("min")]),_c('td',[_vm._v("sek")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_c('hr')])])
}]

export { render, staticRenderFns }